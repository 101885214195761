// styles
import '@/stylesheets/mart/about.scss';

// images
require.context('@/images/mart', true);

// rails-ujs
import Rails from '@rails/ujs';

Rails.start();
